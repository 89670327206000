import React, { useState, useEffect } from "react"
import { useForm, useFieldArray } from "react-hook-form"
import { Col, Container, Form, Row } from "react-bootstrap"
import Layout from "../components/layout/Index"
import Title from "../components/commons/page/title/Index"
import ProgressBar from "../components/commons/progressBar/Index"
import Button from "@material-ui/core/Button"
import ImageIcon from "@material-ui/icons/Image"
import PdfIcon from "@material-ui/icons/PictureAsPdf"
import { withTrans } from "../i18n/withTrans"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"

const Registrasi = ({ t }) => {
  const [step, setStep] = useState(0)
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [hasBranch, setHasBranch] = useState(false)
  const [regionOptions, setRegionOptions] = useState(
    <option disabled selected>
      {t("registration.choose") + t("registration.region")}
    </option>
  )
  const [memberOptions, setMemberOptions] = useState(
    <option disabled selected>
      {t("registration.choose") + t("registration.member")}
    </option>
  )
  const [deviceOptions, setDeviceOptions] = useState(
    <option disabled selected>
      {t("registration.choose") + t("registration.medicalDeviceCategory")}
    </option>
  )
  const nextStep = () => setStep(step + 1)
  const previousStep = () => setStep(step - 1)
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset,
  } = useForm({
    defaultValues: {
      toolsAgencyForm: [{ name: "", medical_device_id: "" }],
      toolsProducedForm: [{ name: "", medical_device_id: "" }],
      ownedCertification: [""],
    },
  })

  useEffect(() => {
    let options = []
    let member_options = []
    let device_options = []
    options.push(
      <option value="" disabled selected>
        {t("registration.choose") + t("registration.region")}
      </option>
    )
    member_options.push(
      <option value="" disabled selected>
        {t("registration.choose") + t("registration.member")}
      </option>
    )
    device_options.push(
      <option value="" disabled selected>
        {t("registration.choose") + t("registration.medicalDeviceCategory")}
      </option>
    )
    async function getAllRegion() {
      let res = await fetch(`${process.env.GATSBY_API_URL}/region?length=999999`)
      const data = await res.json()
      data.data.map(item =>
        options.push(
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        )
      )
      options.push(<option value="lainnya">Lainnya</option>)
      setRegionOptions(options)
    }
    async function getAllMember() {
      let res = await fetch(`${process.env.GATSBY_API_URL}/member`)
      const data = await res.json()
      data.data.map(item =>
        member_options.push(
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        )
      )
      setMemberOptions(member_options)
    }
    async function getAllDevice() {
      let res = await fetch(`${process.env.GATSBY_API_URL}/medical_device`)
      const data = await res.json()
      data.data.map(item =>
        device_options.push(
          <option key={item.id} value={item.id}>
            {item.name}
          </option>
        )
      )
      setDeviceOptions(device_options)
    }
    getAllRegion()
    getAllMember()
    getAllDevice()
  }, [])

  const {
    fields: fieldAgency,
    append: appendAgency,
    remove: removeAgency,
  } = useFieldArray({
    control,
    name: "toolsAgencyForm",
  })

  const {
    fields: fieldProduced,
    append: appendProduced,
    remove: removeProduced,
  } = useFieldArray({
    control,
    name: "toolsProducedForm",
  })

  const {
    fields: fieldCertification,
    append: appendCertification,
    remove: removeCertification,
  } = useFieldArray({
    control,
    name: "ownedCertification",
  })

  const onSubmit = data => {
    if (step < 1) {
      nextStep()
    } else {
      let param = data
      param.img_name = data.member_photo_filename
      param.member_agency_tool_data = data.toolsAgencyForm
      param.member_produced_tool_data = data.toolsProducedForm
      param.owned_certification = [...new Set(data.ownedCertification)].join(
        ","
      )
      param.relation_member_id = data.member_id
      delete param.member_photo_filename
      delete param.member_photo_filename_temp
      delete param.toolsAgencyForm
      delete param.toolsProducedForm
      delete param.ownedCertification
      delete param.deed_of_amendment_filename_temp
      delete param.deed_of_establishment_filename_temp
      delete param.pak_filename_temp
      delete param.siup_filename_temp
      delete param.member_id

      const settings = {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
        body: JSON.stringify(param),
      }
      fetch(`${process.env.GATSBY_API_URL}/candidate_member`, settings)
        .then(response => {
          return response.json()
        })
        .then(result => {
          if (result.status === 200) {
            reset()
            setStep(0)
          }
          setIsSubmitted(result)
        })
        .catch(error => {
          console.error(error)
        })
    }
  }

  const handleUploadFile = async (
    e,
    isMultiple,
    name_input,
    isImage = false
  ) => {
    const fd = new FormData()
    fd.append("file", e.target.files[0])
    const settings = {
      method: "POST",
      body: fd,
    }
    try {
      const res = await fetch(
        `${process.env.GATSBY_API_URL}/file/${
          isImage ? "member_photo" : "member_supporting_files"
        }/upload`,
        settings
      )
      const data = await res.json()
      if (isMultiple) {
        setValue(
          `${e.target.name.split(".")[0]}.name`,
          e.target.files[0].name.split(".")[0]
        )
        setValue(`${e.target.name.split(".")[0]}.filename`, data.data.fileName)
      } else {
        setValue(name_input + "_temp", data.data.fileName)
        setValue(name_input, data.data.fileName)
        delete errors[name_input]
      }
    } catch (e) {
      return e
    }
  }

  const steps = [
    t("registration.companyData"),
    t("registration.companyAdministration"),
  ]
  return (
    <Layout>
      <Container>
        <Title label={t("registration.newMember")} />
        <Row>
          <Col md={7} className="form-registration">
            {/* <div className="d-flex justify-content-between mx-auto progress-bar-parent">
              <ProgressBar
                isFirst
                isActive
                progressLabel={t("registration.companyData")}
              />
              <ProgressBar
                isActive={step > 1}
                progressLabel={t("registration.companyAdministration")}
              />
            </div> */}
            <div>
              <Stepper activeStep={step} alternativeLabel>
                {steps.map(label => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </div>
            {(step === 0 || isSubmitted) && (
              <div
                className={`form-info ${
                  isSubmitted.status === 500 && "error-info"
                }`}
              >
                <span>
                  {isSubmitted
                    ? isSubmitted.status === 500
                      ? isSubmitted.message
                      : t("registration.successRegistration")
                    : t("registration.registrationMessage")}
                </span>
              </div>
            )}
            <div className="main-form">
              <Form onSubmit={handleSubmit(onSubmit)}>
                {step === 0 && (
                  <>
                    <span className="form-section-title">
                      {t("registration.companyData")}
                    </span>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.region")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="select"
                          {...register("region_id", {
                            required: true,
                            validate: value =>
                              value !==
                              t("registration.choose") +
                                t("registration.region"),
                          })}
                          className={`${errors.region_id && "is-invalid"}`}
                        >
                          {regionOptions}
                        </Form.Control>
                        <p className="error-message">
                          {errors.region_id &&
                            t("registration.region") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.companyName")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder={
                            t("registration.enter") +
                            t("registration.companyName")
                          }
                          {...register("name", { required: true })}
                          className={`${errors.name && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.name &&
                            t("registration.companyName") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.branchCompany")}
                      </Form.Label>
                      <Col sm="8">
                        <div className="mt-2">
                          <input
                            type="checkbox"
                            checked={hasBranch}
                            onChange={e => {
                              setHasBranch(e.target.checked)
                            }}
                          />
                        </div>
                        {hasBranch && (
                          <>
                            <Form.Control
                              as="select"
                              {...register("member_id", {
                                required: true,
                                validate: value =>
                                  value !==
                                  t("registration.choose") +
                                    t("registration.branchCompany"),
                              })}
                              className={`${errors.region_id && "is-invalid"}`}
                            >
                              {memberOptions}
                            </Form.Control>
                            <p className="error-message">
                              {errors.region_id &&
                                t("registration.branchCompany") +
                                  t("registration.required")}
                            </p>
                          </>
                        )}
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.companyLeaderName")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder={
                            t("registration.enter") +
                            t("registration.companyLeaderName")
                          }
                          {...register("leader_name", { required: true })}
                          className={`${errors.leader_name && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.leader_name &&
                            t("registration.companyLeaderName") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.positionInCompany")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder={
                            t("registration.enter") +
                            t("registration.positionInCompany")
                          }
                          {...register("position", { required: true })}
                          className={`${errors.position && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.position &&
                            t("registration.positionInCompany") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.companyAddress")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder={
                            t("registration.enter") +
                            t("registration.companyAddress")
                          }
                          {...register("address", { required: true })}
                          className={`${errors.address && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.address &&
                            t("registration.companyAddress") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.companyPhoneNumber")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="number"
                          placeholder={
                            t("registration.enter") +
                            t("registration.companyPhoneNumber")
                          }
                          {...register("phone_number", { required: true })}
                          className={`${errors.phone_number && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.phone_number &&
                            t("registration.companyPhoneNumber") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.companyWebsite")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="url"
                          placeholder={
                            t("registration.enter") +
                            t("registration.companyWebsite")
                          }
                          {...register("website")}
                          className={`${errors.website && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.website &&
                            t("registration.companyWebsite") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.companyEmail")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="email"
                          placeholder={
                            t("registration.enter") +
                            t("registration.companyEmail")
                          }
                          {...register("email", { required: true })}
                          className={`${errors.email && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.email &&
                            t("registration.companyEmail") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.contactPerson")}
                      </Form.Label>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          placeholder={
                            t("registration.enter") +
                            t("registration.contactPersonName") +
                            " 1"
                          }
                          {...register("contact_person_name_1", {
                            required: true,
                          })}
                          className={`${
                            errors.contact_person_name_1 && "is-invalid"
                          }`}
                        />
                        <p className="error-message">
                          {errors.contact_person_name_1 &&
                            t("registration.contactPersonName") +
                              " 1" +
                              t("registration.required")}
                        </p>
                        <Form.Control
                          type="email"
                          placeholder={
                            t("registration.enter") +
                            t("registration.contactPersonEmail") +
                            " 1"
                          }
                          {...register("contact_person_email_1", {
                            required: true,
                          })}
                          className={`${
                            errors.contact_person_email_1 && "is-invalid"
                          }`}
                        />
                        <p className="error-message">
                          {errors.contact_person_email_1 &&
                            t("registration.contactPersonEmail") +
                              " 1" +
                              t("registration.required")}
                        </p>
                        <Form.Control
                          type="number"
                          placeholder={
                            t("registration.enter") +
                            t("registration.contactPersonPhone")
                          }
                          {...register("contact_person_phone_number_1", {
                            required: true,
                          })}
                          className={`${
                            errors.contact_person_phone_number_1 && "is-invalid"
                          }`}
                        />
                        <p className="error-message">
                          {errors.contact_person_phone_number_1 &&
                            t("registration.contactPersonPhone") +
                              " 1" +
                              t("registration.required")}
                        </p>
                      </Col>
                      <Col sm="4">
                        <Form.Control
                          type="text"
                          placeholder={
                            t("registration.enter") +
                            t("registration.contactPersonName") +
                            " 2"
                          }
                          {...register("contact_person_name_2")}
                        />
                        <p className="error-message">
                          {errors.contact_person_name_2 &&
                            t("registration.contactPersonEmail") +
                              " 1" +
                              t("registration.required")}
                        </p>
                        <Form.Control
                          type="email"
                          placeholder={
                            t("registration.enter") +
                            t("registration.contactPersonEmail") +
                            " 2"
                          }
                          {...register("contact_person_email_2")}
                        />
                        <p className="error-message">
                          {errors.contact_person_email_2 &&
                            t("registration.contactPersonEmail") +
                              " 1" +
                              t("registration.required")}
                        </p>
                        <Form.Control
                          type="number"
                          placeholder={
                            t("registration.enter") +
                            t("registration.contactPersonPhone")
                          }
                          {...register("contact_person_phone_number_2")}
                          className={`${
                            errors.contact_person_phone_number_2 && "is-invalid"
                          }`}
                        />
                        <p className="error-message">
                          {errors.contact_person_phone_number_2 &&
                            t("registration.contactPersonPhone") +
                              " 2" +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.institutional")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          as="select"
                          {...register("institutional", {
                            required: true,
                            validate: value =>
                              value !==
                              t("registration.choose") +
                                t("registration.institutional"),
                          })}
                          className={`${errors.institutional && "is-invalid"}`}
                        >
                          <option disabled selected>
                            {t("registration.choose") +
                              t("registration.institutional")}
                          </option>
                          <option value="distributor">Distributor</option>
                          <option value="eksportir">Eksportir</option>
                          <option value="importir">Importir</option>
                          <option value="agen tunggal">Agen Tunggal</option>
                          <option value="industri">Industri</option>
                        </Form.Control>
                        <p className="error-message">
                          {errors.institutional &&
                            t("registration.institutional") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.companyFacilities")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder={t("registration.factory")}
                          {...register("owned_facility_factory")}
                          className={`${
                            errors.owned_facility_factory && "is-invalid"
                          }`}
                        />
                        <p className="error-message">
                          {errors.owned_facility_factory &&
                            t("registration.factoryAddress") +
                              t("registration.required")}
                        </p>
                      </Col>
                      <Col sm={{ span: 8, offset: 4 }}>
                        <Form.Control
                          type="text"
                          placeholder={t("registration.workshop")}
                          {...register("owned_facility_workshop")}
                          className={`${
                            errors.owned_facility_workshop && "is-invalid"
                          }`}
                        />
                        <p className="error-message">
                          {errors.owned_facility_workshop &&
                            t("registration.workshopAddress") +
                              t("registration.required")}
                        </p>
                      </Col>
                      <Col sm={{ span: 8, offset: 4 }}>
                        <Form.Control
                          type="text"
                          placeholder={t("registration.others")}
                          {...register("owned_facility_other")}
                          className={`${
                            errors.owned_facility_other && "is-invalid"
                          }`}
                        />
                        <p className="error-message">
                          {errors.owned_facility_other &&
                            t("registration.othersCompanyFacilities") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.expertName")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder={t("registration.name")}
                          {...register("company_technicians_name", {
                            required: true,
                          })}
                          className={`${
                            errors.company_technicians_name && "is-invalid"
                          }`}
                        />
                        <p className="error-message">
                          {errors.company_technicians_name &&
                            t("registration.expertName") +
                              t("registration.required")}
                        </p>
                      </Col>
                      <Col sm={{ span: 8, offset: 4 }}>
                        <Form.Control
                          type="text"
                          placeholder={t("registration.education")}
                          {...register("company_technicians_education", {
                            required: true,
                          })}
                          className={`${
                            errors.company_technicians_education && "is-invalid"
                          }`}
                        />
                        <p className="error-message">
                          {errors.company_technicians_education &&
                            t("registration.education") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column md={4}>
                        {t("registration.uploadPhoto")}
                      </Form.Label>
                      <Form.Control
                        type="text"
                        style={{ display: "none" }}
                        {...register("member_photo_filename", {
                          required: true,
                        })}
                      />
                      <Col md={8} className="ps-0">
                        <div className="file-upload-container mb-3">
                          <Form.Group>
                            <div className="filename-section mb-3">
                              <Form.Control
                                type="text"
                                disabled
                                {...register("member_photo_filename_temp")}
                              />
                            </div>
                            <div className="file-section">
                              <Button
                                variant="contained"
                                component="label"
                                className="primary-mui-btn"
                                disabled={false}
                                startIcon={
                                  //   isDone === true ? (
                                  <ImageIcon />
                                  //   ) : (
                                  //     <CircularProgress size={14} color="secondary" />
                                  //   )
                                }
                              >
                                {t("registration.uploadPhoto")}
                                <input
                                  name={`member_photo_file`}
                                  onChange={e =>
                                    handleUploadFile(
                                      e,
                                      false,
                                      "member_photo_filename",
                                      true
                                    )
                                  }
                                  type="file"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                />
                              </Button>
                            </div>
                          </Form.Group>
                        </div>
                        <p className="error-message">
                          {errors.member_photo_filename &&
                            t("registration.uploadPhoto") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                      <Button
                        type="submit"
                        variant="contained"
                        className="primary-mui-btn"
                      >
                        {t("registration.next")}
                      </Button>
                    </div>
                  </>
                )}
                {step === 1 && (
                  <>
                    <span className="form-section-title">
                      {t("registration.companyAdministration")}
                    </span>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.siupNumber")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder={
                            t("registration.enter") +
                            t("registration.siupNumber")
                          }
                          {...register("siup", {
                            required: true,
                          })}
                          className={`${errors.siup && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.siup &&
                            t("registration.siupNumber") +
                              t("registration.required")}
                        </p>
                        <Form.Control
                          type="date"
                          {...register("siup_date", {
                            required: true,
                          })}
                          className={`${errors.siup_date && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.siup_date &&
                            t("registration.siupDate") +
                              t("registration.required")}
                        </p>
                        <Form.Control
                          type="text"
                          style={{ display: "none" }}
                          {...register("siup_filename", {
                            required: true,
                          })}
                        />
                        <div className="file-upload-container mb-3">
                          <Form.Group>
                            <div className="filename-section mb-3">
                              <Form.Control
                                type="text"
                                disabled
                                {...register("siup_filename_temp")}
                              />
                            </div>
                            <div className="file-section">
                              <Button
                                variant="contained"
                                component="label"
                                className="primary-mui-btn"
                                disabled={false}
                                startIcon={<PdfIcon />}
                              >
                                {t("registration.uploadFileCaps")}
                                <input
                                  name={`siup_filename`}
                                  onChange={e =>
                                    handleUploadFile(e, false, "siup_filename")
                                  }
                                  type="file"
                                  accept="application/pdf"
                                  style={{ display: "none" }}
                                />
                              </Button>
                            </div>
                          </Form.Group>
                        </div>
                        <p className="error-message">
                          {errors.siup_filename &&
                            t("registration.uploadFileCaps") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.qualification")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Check
                          inline
                          label={t("registration.small")}
                          name="qualification"
                          type="radio"
                          id={`radio-qualification-1`}
                          value="kecil"
                          {...register("qualification", {
                            required: true,
                          })}
                          className={`${errors.qualification && "is-invalid"}`}
                        />
                        <Form.Check
                          inline
                          label={t("registration.medium")}
                          name="qualification"
                          type="radio"
                          id={`radio-qualification-2`}
                          value="menengah"
                          {...register("qualification", {
                            required: true,
                          })}
                          className={`${errors.qualification && "is-invalid"}`}
                        />
                        <Form.Check
                          inline
                          label={t("registration.large")}
                          name="qualification"
                          type="radio"
                          value="besar"
                          id={`radio-qualification-3`}
                          {...register("qualification", {
                            required: true,
                          })}
                          className={`${errors.qualification && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.qualification &&
                            t("registration.qualification") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.pakNumber")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder={
                            t("registration.enter") +
                            t("registration.pakNumber")
                          }
                          {...register("pak", {
                            required: true,
                          })}
                          className={`${errors.pak && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.pak &&
                            t("registration.pakNumber") +
                              t("registration.required")}
                        </p>
                        <Form.Control
                          type="date"
                          {...register("pak_date", {
                            required: true,
                          })}
                          className={`${errors.pak_date && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.pak_date &&
                            t("registration.pakDate") +
                              t("registration.required")}
                        </p>
                        <Form.Control
                          type="text"
                          style={{ display: "none" }}
                          {...register("pak_filename", {
                            required: true,
                          })}
                        />
                        <div className="file-upload-container mb-3">
                          <Form.Group>
                            <div className="filename-section mb-3">
                              <Form.Control
                                type="text"
                                disabled
                                {...register("pak_filename_temp")}
                              />
                            </div>
                            <div className="file-section">
                              <Button
                                variant="contained"
                                component="label"
                                className="primary-mui-btn"
                                disabled={false}
                                startIcon={<PdfIcon />}
                              >
                                {t("registration.uploadFileCaps")}
                                <input
                                  name={`pak_filename`}
                                  onChange={e =>
                                    handleUploadFile(e, false, "pak_filename")
                                  }
                                  type="file"
                                  accept="application/pdf"
                                  style={{ display: "none" }}
                                />
                              </Button>
                            </div>
                          </Form.Group>
                        </div>
                        <p className="error-message">
                          {errors.pak_filename &&
                            t("registration.uploadFileCaps") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.notarialDeedEstablishment")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder={
                            t("registration.enter") +
                            t("registration.notarialDeedEstablishment")
                          }
                          {...register("deed_of_establishment_number", {
                            required: true,
                          })}
                          className={`${
                            errors.deed_of_establishment_number && "is-invalid"
                          }`}
                        />
                        <p className="error-message">
                          {errors.deed_of_establishment_number &&
                            t("registration.notarialDeedEstablishment") +
                              t("registration.required")}
                        </p>
                      </Col>
                      <Col sm={{ span: 8, offset: 4 }}>
                        <Form.Control
                          type="date"
                          {...register("deed_of_establishment_date", {
                            required: true,
                          })}
                          className={`${
                            errors.deed_of_establishment_date && "is-invalid"
                          }`}
                        />
                        <p className="error-message">
                          {errors.deed_of_establishment_date &&
                            t("registration.notarialDeedEstablishmentDate") +
                              t("registration.required")}
                        </p>
                        <Form.Control
                          type="text"
                          style={{ display: "none" }}
                          {...register("deed_of_establishment_filename", {
                            required: true,
                          })}
                        />
                        <div className="file-upload-container mb-3">
                          <Form.Group>
                            <div className="filename-section mb-3">
                              <Form.Control
                                type="text"
                                disabled
                                {...register(
                                  "deed_of_establishment_filename_temp"
                                )}
                              />
                            </div>
                            <div className="file-section">
                              <Button
                                variant="contained"
                                component="label"
                                className="primary-mui-btn"
                                disabled={false}
                                startIcon={<PdfIcon />}
                              >
                                {t("registration.uploadFileCaps")}
                                <input
                                  name={`deed_of_establishment_filename`}
                                  onChange={e =>
                                    handleUploadFile(
                                      e,
                                      false,
                                      "deed_of_establishment_filename"
                                    )
                                  }
                                  type="file"
                                  accept="application/pdf"
                                  style={{ display: "none" }}
                                />
                              </Button>
                            </div>
                          </Form.Group>
                        </div>
                        <p className="error-message">
                          {errors.deed_of_establishment_filename &&
                            t("registration.uploadFileCaps") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.notatialDeedAmendment")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder={
                            t("registration.enter") +
                            t("registration.notatialDeedAmendment")
                          }
                          {...register("deed_of_amendment_number", {
                            required: true,
                          })}
                          className={`${
                            errors.deed_of_amendment_number && "is-invalid"
                          }`}
                        />
                        <p className="error-message">
                          {errors.deed_of_amendment_number &&
                            t("registration.notatialDeedAmendment") +
                              t("registration.required")}
                        </p>
                      </Col>
                      <Col sm={{ span: 8, offset: 4 }}>
                        <Form.Control
                          type="date"
                          placeholder=""
                          {...register("deed_of_amendment_date", {
                            required: true,
                          })}
                          className={`${
                            errors.deed_of_amendment_date && "is-invalid"
                          }`}
                        />
                        <p className="error-message">
                          {errors.deed_of_amendment_date &&
                            t("registration.notarialDeedAmendmentDate") +
                              t("registration.required")}
                        </p>
                        <Form.Control
                          type="text"
                          style={{ display: "none" }}
                          {...register("deed_of_amendment_filename", {
                            required: true,
                          })}
                        />
                        <div className="file-upload-container mb-3">
                          <Form.Group>
                            <div className="filename-section mb-3">
                              <Form.Control
                                type="text"
                                disabled
                                {...register("deed_of_amendment_filename_temp")}
                              />
                            </div>
                            <div className="file-section">
                              <Button
                                variant="contained"
                                component="label"
                                className="primary-mui-btn"
                                disabled={false}
                                startIcon={<PdfIcon />}
                              >
                                {t("registration.uploadFileCaps")}
                                <input
                                  name={`deed_of_amendment_filename`}
                                  onChange={e =>
                                    handleUploadFile(
                                      e,
                                      false,
                                      "deed_of_amendment_filename"
                                    )
                                  }
                                  type="file"
                                  accept="application/pdf"
                                  style={{ display: "none" }}
                                />
                              </Button>
                            </div>
                          </Form.Group>
                        </div>
                        <p className="error-message">
                          {errors.deed_of_amendment_filename &&
                            t("registration.uploadFileCaps") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.companyRegistrationNumber")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          {...register("tdp", {
                            required: true,
                          })}
                          className={`${errors.tdp && "is-invalid"}`}
                          placeholder={
                            t("registration.enter") +
                            t("registration.companyRegistrationNumber")
                          }
                        />
                        <p className="error-message">
                          {errors.tdp &&
                            t("registration.companyRegistrationNumber") +
                              t("registration.required")}
                        </p>
                      </Col>
                      <Col sm={{ span: 8, offset: 4 }}>
                        <Form.Control
                          type="date"
                          {...register("tdp_date", {
                            required: true,
                          })}
                          className={`${errors.tdp_date && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.tdp_date &&
                            t("registration.companyRegistrationNumberDate") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.companyMandatoryNumber")}
                      </Form.Label>
                      <Col sm="8">
                        <Form.Control
                          type="text"
                          placeholder={
                            t("registration.enter") +
                            t("registration.companyMandatoryNumber")
                          }
                          {...register("npwp", {
                            required: true,
                          })}
                          className={`${errors.npwp && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.npwp &&
                            t("registration.companyMandatoryNumber") +
                              t("registration.required")}
                        </p>
                      </Col>
                      <Col sm={{ span: 8, offset: 4 }}>
                        <Form.Control
                          type="date"
                          {...register("npwp_date", {
                            required: true,
                          })}
                          className={`${errors.npwp_date && "is-invalid"}`}
                        />
                        <p className="error-message">
                          {errors.npwp_date &&
                            t("registration.companyMandatoryNumberDate") +
                              t("registration.required")}
                        </p>
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row}>
                      <Form.Label column sm="4">
                        {t("registration.ownedCertification")}
                      </Form.Label>
                      {/* <Col sm="8">
                        <Form.Control
                          as="select"
                          {...register("owned_certification", {
                            required: true,
                            validate: value =>
                              value !==
                              t("registration.choose") +
                                t("registration.ownedCertification"),
                          })}
                          className={`${
                            errors.owned_certification && "is-invalid"
                          }`}
                        >
                          <option disabled selected>
                            {t("registration.choose") +
                              t("registration.ownedCertification")}
                          </option>
                          <option value="cdakb">CDAKB</option>
                          <option value="cpakb">CPAKB</option>
                          <option value="iso_13485">ISO 13485</option>
                          <option value="iso_9001">ISO 9001</option>
                          <option value="iso_14001">ISO 14001</option>
                          <option value="iso_11135">ISO 11135</option>
                          <option value="iso_37001">ISO 37001</option>
                          <option value="fda">FDA</option>
                          <option value="ce">CE</option>
                        </Form.Control>
                        <p className="error-message">
                          {errors.owned_certification &&
                            t("registration.ownedCertification") +
                              t("registration.required")}
                        </p>
                      </Col> */}
                      {fieldCertification.map((item, index) => {
                        return (
                          <>
                            <Col
                              md={{ span: 7, offset: index === 0 ? 0 : 4 }}
                              // className="mb-3"
                              key={item.id}
                            >
                              <Form.Control
                                as="select"
                                {...register(`ownedCertification[${index}]`, {
                                  required: true,
                                  validate: value =>
                                    value !==
                                    t("registration.choose") +
                                      t("registration.ownedCertification"),
                                })}
                                className={`${
                                  errors.ownedCertification &&
                                  errors.ownedCertification[index] &&
                                  "is-invalid"
                                }`}
                              >
                                <option disabled selected>
                                  {t("registration.choose") +
                                    t("registration.ownedCertification")}
                                </option>
                                <option value="cdakb">CDAKB</option>
                                <option value="cpakb">CPAKB</option>
                                <option value="iso 13485">ISO 13485</option>
                                <option value="iso 9001">ISO 9001</option>
                                <option value="iso 14001">ISO 14001</option>
                                <option value="iso 11135">ISO 11135</option>
                                <option value="iso 37001">ISO 37001</option>
                                <option value="fda">FDA</option>
                                <option value="ce">CE</option>
                              </Form.Control>
                              <p className="error-message">
                                {errors.ownedCertification &&
                                  errors.ownedCertification[index] &&
                                  t("registration.medicalDeviceCategory") +
                                    t("registration.required")}
                              </p>
                            </Col>
                            <Col
                              md={{ span: 1 }}
                              // className="ps-0"
                              key={item.index}
                            >
                              <svg
                                onClick={() => removeCertification(index)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#bcbcbc"
                                class="bi bi-x-circle-fill align-self-center remove-file"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                              </svg>
                            </Col>
                          </>
                        )
                      })}
                    </Form.Group>
                    <Col
                      className="d-flex add-field-container mb-3"
                      md={{ span: 8, offset: 4 }}
                    >
                      <hr />
                      <Button
                        type="button"
                        variant="contained"
                        component="label"
                        className="primary-mui-btn"
                        onClick={() => appendCertification("")}
                      >
                        {t("registration.addSertification")}
                      </Button>
                      <hr />
                    </Col>
                    <Form.Group as={Row}>
                      <Form.Label column md={4}>
                        {t("registration.deviceAgency")}
                      </Form.Label>
                      {fieldAgency.map((item, index) => {
                        return (
                          <>
                            <Col
                              md={{ span: 7, offset: index === 0 ? 0 : 4 }}
                              // className="mb-3"
                              key={item.id}
                            >
                              <Form.Control
                                as="select"
                                {...register(
                                  `toolsAgencyForm[${index}].medical_device_id`,
                                  {
                                    required: true,
                                    validate: value =>
                                      value !==
                                      t("registration.choose") +
                                        t("registration.medicalDeviceCategory"),
                                  }
                                )}
                                className={`${
                                  errors.toolsAgencyForm &&
                                  errors.toolsAgencyForm[index] &&
                                  errors.toolsAgencyForm[index]
                                    .medical_device_id &&
                                  "is-invalid"
                                }`}
                              >
                                {deviceOptions}
                              </Form.Control>
                              <p className="error-message">
                                {errors.toolsAgencyForm &&
                                  errors.toolsAgencyForm[index] &&
                                  errors.toolsAgencyForm[index]
                                    .medical_device_id &&
                                  t("registration.medicalDeviceCategory") +
                                    t("registration.required")}
                              </p>
                              <Form.Control
                                type="text"
                                defaultValue={`${item.name}`}
                                placeholder={
                                  t("registration.enter") +
                                  t("registration.productName")
                                }
                                {...register(`toolsAgencyForm[${index}].name`, {
                                  required: true,
                                })}
                                className={`${
                                  errors.toolsAgencyForm &&
                                  errors.toolsAgencyForm[index] &&
                                  errors.toolsAgencyForm[index].name &&
                                  "is-invalid"
                                }`}
                              />
                              <p className="error-message">
                                {errors.toolsAgencyForm &&
                                  errors.toolsAgencyForm[index] &&
                                  errors.toolsAgencyForm[index].name &&
                                  t("registration.productName") +
                                    t("registration.required")}
                              </p>
                            </Col>
                            <Col
                              md={{ span: 1 }}
                              // className="ps-0"
                              key={item.index}
                            >
                              <svg
                                onClick={() => removeAgency(index)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#bcbcbc"
                                class="bi bi-x-circle-fill align-self-center remove-file"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                              </svg>
                            </Col>
                          </>
                        )
                      })}
                    </Form.Group>
                    <Col
                      className="d-flex add-field-container mb-3"
                      md={{ span: 8, offset: 4 }}
                    >
                      <hr />
                      <Button
                        type="button"
                        variant="contained"
                        component="label"
                        className="primary-mui-btn"
                        onClick={() =>
                          appendAgency({ name: "", medical_device_id: "" })
                        }
                      >
                        {t("registration.addCategory")}
                      </Button>
                      <hr />
                    </Col>
                    <Form.Group as={Row}>
                      <Form.Label column md={4}>
                        {t("registration.deviceProduced")}
                      </Form.Label>
                      {fieldProduced.map((item, index) => {
                        return (
                          <>
                            <Col
                              md={{ span: 7, offset: index === 0 ? 0 : 4 }}
                              // className="mb-3"
                              key={item.id}
                            >
                              <Form.Control
                                as="select"
                                {...register(
                                  `toolsProducedForm[${index}].medical_device_id`,
                                  {
                                    required: true,
                                    validate: value =>
                                      value !==
                                      t("registration.choose") +
                                        t("registration.medicalDeviceCategory"),
                                  }
                                )}
                                className={`${
                                  errors.toolsProducedForm &&
                                  errors.toolsProducedForm[index] &&
                                  errors.toolsProducedForm[index]
                                    .medical_device_id &&
                                  "is-invalid"
                                }`}
                              >
                                {deviceOptions}
                              </Form.Control>
                              <p className="error-message">
                                {errors.toolsProducedForm &&
                                  errors.toolsProducedForm[index] &&
                                  errors.toolsProducedForm[index]
                                    .medical_device_id &&
                                  t("registration.medicalDeviceCategory") +
                                    t("registration.required")}
                              </p>
                              <Form.Control
                                type="text"
                                defaultValue={`${item.name}`}
                                placeholder={
                                  t("registration.enter") +
                                  t("registration.productName")
                                }
                                {...register(
                                  `toolsProducedForm[${index}].name`,
                                  {
                                    required: true,
                                  }
                                )}
                                className={`${
                                  errors.toolsProducedForm &&
                                  errors.toolsProducedForm[index] &&
                                  errors.toolsProducedForm[index].name &&
                                  "is-invalid"
                                }`}
                              />
                              <p className="error-message">
                                {errors.toolsProducedForm &&
                                  errors.toolsProducedForm[index] &&
                                  errors.toolsProducedForm[index].name &&
                                  t("registration.productName") +
                                    t("registration.required")}
                              </p>
                            </Col>
                            <Col
                              md={{ span: 1 }}
                              // className="ps-0"
                              key={item.index}
                            >
                              <svg
                                onClick={() => removeProduced(index)}
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="#bcbcbc"
                                class="bi bi-x-circle-fill align-self-center remove-file"
                                viewBox="0 0 16 16"
                              >
                                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM5.354 4.646a.5.5 0 1 0-.708.708L7.293 8l-2.647 2.646a.5.5 0 0 0 .708.708L8 8.707l2.646 2.647a.5.5 0 0 0 .708-.708L8.707 8l2.647-2.646a.5.5 0 0 0-.708-.708L8 7.293 5.354 4.646z" />
                              </svg>
                            </Col>
                          </>
                        )
                      })}
                    </Form.Group>
                    <Col
                      className="d-flex add-field-container mb-3"
                      md={{ span: 8, offset: 4 }}
                    >
                      <hr />
                      <Button
                        type="button"
                        variant="contained"
                        component="label"
                        className="primary-mui-btn"
                        onClick={() =>
                          appendProduced({ name: "", medical_device_id: "" })
                        }
                      >
                        {t("registration.addCategory")}
                      </Button>
                      <hr />
                    </Col>
                    <div className="d-flex justify-content-between">
                      <Button
                        variant="outlined"
                        className="primary-mui-btn"
                        onClick={previousStep}
                      >
                        {t("registration.back")}
                      </Button>
                      <Button
                        type="submit"
                        variant="contained"
                        className="primary-mui-btn"
                      >
                        {t("registration.send")}
                      </Button>
                    </div>
                  </>
                )}
              </Form>
            </div>
          </Col>
          <Col md={{ span: 4, offset: 1 }} className="member-benefit">
            <div className="info">
              <span>{t("registration.benefitMember")}</span>
            </div>
            <div className="desc">
              <ul>
                <li>
                  <span>{t("registration.benefitMember1")}</span>
                </li>
                <li>{t("registration.benefitMember2")}</li>
                <li>{t("registration.benefitMember3")}</li>
                <li>{t("registration.benefitMember4")}</li>
                <li>{t("registration.benefitMember5")}</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default withTrans(Registrasi)
